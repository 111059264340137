import { APICore } from 'helpers/api/apiCore';
import { useRedux } from './index';
import { infoUser } from '../redux/auth/actions';
import { UserRoleType } from '../types/user/role';

export default function useUser() {
    const { dispatch, appSelector } = useRedux();
    const api = new APICore();

    const { userInfo } = appSelector((state) => ({
        userInfo: state.Auth.info,
    }));

    const userLoadInfo = () => {
        dispatch(infoUser());
    };

    const loggedInUser = api.getLoggedInUser() || { roles: [UserRoleType.GUEST] };
    const loggedInUserRoles = loggedInUser.roles;
    loggedInUser.roleLabel = loggedInUserRoles[0];
    return [loggedInUser, loggedInUserRoles, userInfo, userLoadInfo];
}
