import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { staticPageApiResponseSuccess, staticPageApiResponseError } from './actions';
import { apiUserView, apiAdminCreate, apiAdminUpdate, apiAdminViewDetails, apiAdminViewAll } from '../../../helpers/api/cms/staticPage';
import { PageActionTypes } from './constants';
import { StaticPageData } from '../../../types/cms/staticPage';

type AdminPagesPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminPagesDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type AdminPageUpsertPayloadType = {
    payload: StaticPageData;
    type: string;
};

type UserPagePayloadType = {
    payload: {
        slug: string;
    };
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminPagesPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        yield put(staticPageApiResponseSuccess(PageActionTypes.ADMIN_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(staticPageApiResponseError(PageActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminPageUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(staticPageApiResponseSuccess(PageActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(staticPageApiResponseError(PageActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminPageUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(staticPageApiResponseSuccess(PageActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(staticPageApiResponseError(PageActionTypes.ADMIN_UPSERT, error));
    }
}

function* userViewDetails({ payload: { slug } }: UserPagePayloadType): SagaIterator {
    try {
        const response = yield call(apiUserView, slug);
        yield put(staticPageApiResponseSuccess(PageActionTypes.USER_VIEW, response.data));
    } catch (error: any) {
        yield put(staticPageApiResponseError(PageActionTypes.USER_VIEW, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(PageActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(PageActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(PageActionTypes.ADMIN_UPSERT, adminUpsert);
}

export function* watchUserViewDetails() {
    yield takeEvery(PageActionTypes.USER_VIEW, userViewDetails);
}

function* staticPageSaga() {
    yield all([fork(watchUserViewDetails), fork(watchAdminViewAll), fork(watchAdminViewDetails), fork(watchAdminUpsert)]);
}

export default staticPageSaga;
