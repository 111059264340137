import React from 'react';
import { Tab } from 'react-bootstrap';
import { TabItem } from '../../../../types/tabItem';

type TabContentProps = {
    content: TabItem[];
};

const HorizontalTabContent = ({ content }: TabContentProps) => {
    return (
        <Tab.Content>
            {content.map((tab, index) => {
                return (
                    <Tab.Pane eventKey={tab.key} id={tab.id} key={index.toString()}>
                        {tab.component}
                    </Tab.Pane>
                );
            })}
        </Tab.Content>
    );
};

export default HorizontalTabContent;
