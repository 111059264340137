import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getDefaultCookieCreation, getDefaultCookieId } from '../../../../utils/cookie';

const CookieConsent = () => {
    const { t } = useTranslation(['cookie']);
    return (
        <>
            <Row>
                <Col style={{ letterSpacing: '0.05em', fontFamily: 'SansSerif, sans-serif' }}>
                    <p className={'font-18'} style={{ fontWeight: '700' }}>
                        {t('title')}
                    </p>
                    <span className={'font-14'} style={{ letterSpacing: '0.05em', lineHeight: '1.75rem' }}>
                        {t('descriptionStart')}
                    </span>{' '}
                    <Link to={'/pages/politica-confidentialitate'} className={'font-14'} style={{ letterSpacing: '0.05em', lineHeight: '1.75rem', fontWeight: '700' }} target={'_blank'}>
                        {t('descriptionPrivacyTitle')}
                    </Link>
                    <span className={'font-14'} style={{ letterSpacing: '0.05em', lineHeight: '1.75rem' }}>
                        {t('descriptionEnd')}
                    </span>{' '}
                </Col>
            </Row>
            <Row>
                <Col style={{ letterSpacing: '0.05em', fontFamily: 'SansSerif, sans-serif' }}>
                    <div className={'p-3 my-3 border border-3 rounded-3'}>
                        <span style={{ fontWeight: 700 }}>{t('defaultId')}: </span>
                        <span>{getDefaultCookieId()}</span>
                        <br />
                        <br />
                        <span style={{ fontWeight: 700 }}>{t('defaultCreation')}: </span>
                        <span>{getDefaultCookieCreation()}</span>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default CookieConsent;
