import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ContactData as ContactDataProps } from '../../../types/cms/contact';

const api = new APICore();

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/contact/page/${page}/${size}`, null);
}

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/contact/${id}`, null);
}

function apiUserSubmit(params: ContactDataProps) {
    return api.create(`${apiBaseUrl}/contact`, params);
}

export { apiUserSubmit, apiAdminViewAll, apiAdminViewDetails };
