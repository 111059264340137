export enum CookieCategory {
    MANDATORY = 'mandatory',
    MARKETING = 'marketing',
    PREFERENCES = 'preferences',
    STATISTICS = 'statistics',
    UNCLASSIFIED = 'unclassified',
}

export interface CookieCategoryDefinition {
    id: number;
    description: string;
    enabled: boolean;
    state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    title: string;
    type: CookieCategory;
}

export enum SAME_SITE_OPTIONS {
    STRICT = 'strict',
    LAX = 'lax',
    NONE = 'none',
}

export interface CookieData {
    type: string;
    description: string;
    domain: string;
    expiration: string;
    title: string;
}

export interface SiteCookie {
    type: CookieCategory;
    data: CookieData[];
}

export const siteCookies: SiteCookie[] = [
    {
        type: CookieCategory.MANDATORY,
        data: [
            {
                domain: 'popasuriromanesti.ro',
                title: 'prm_cookie_id',
                description: "This cookie is used to identify the user's session. It is a session cookie.",
                expiration: '1 year',
                type: 'HTTP',
            },
            {
                domain: 'popasuriromanesti.ro',
                title: 'prm_cookie_created',
                description: "This cookie stores the date when the user's cookie consent was created.",
                expiration: '1 year',
                type: 'HTTP',
            },
            {
                domain: 'popasuriromanesti.ro',
                title: 'prm_cookie_consent',
                description: "This is a mandatory cookie that stores the user's cookie consent preferences.",
                expiration: '1 year',
                type: 'HTTP',
            },
        ],
    },
    {
        type: CookieCategory.MARKETING,
        data: [
            {
                domain: 'Google',
                title: 'IDE',
                description:
                    "Used by Google DoubleClick to register and report the website user's actions after viewing or clicking " +
                    "one of the advertiser's ads with the purpose of measuring the efficacy of an ad and to " +
                    'present targeted ads to the user.',
                expiration: '1 year',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: 'NID',
                description: "Registers a unique ID that identifies a returning user's device. The ID is used for targeted ads.",
                expiration: '6 months',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: '1P_JAR',
                description: 'These cookies are used statistics and track conversion rates and Google ad personalisation.',
                expiration: '1 month',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: 'ANID',
                description: 'These cookies are used to collect anonymous website statistics and track conversion rates.',
                expiration: '1 year',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: 'CONSENT',
                description: "Google cookie. Airship uses this cookie to track the user's consent preferences.",
                expiration: '20 years',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: 'DV',
                description: 'Google cookie. By using this cookie, Google is able to make the ads more attractive to users and more ',
                expiration: '1 day',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: 'HSID',
                description: "Google cookie. Google uses this cookie to track the user's consent preferences.",
                expiration: '2 years',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: 'SAPISID',
                description: 'Google cookie. Protects user data from unauthorized access.',
                expiration: '2 years',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: 'SID',
                description: "Google cookie. Security cookie to protect a user's data from unauthorized access.",
                expiration: '2 years',
                type: 'HTTP',
            },
            {
                domain: 'Google',
                title: 'SIDCC',
                description: 'Google cookie. Cookie is used to protect the user data from unauthorized access.',
                expiration: '3 months',
                type: 'HTTP',
            },
            {
                domain: 'Hotjar',
                title: 'hjAbsoluteSessionInProgress',
                description: 'This cookie is used to count how many times a website has been visited by different visitors.',
                expiration: 'Session',
                type: 'HTTP',
            },
            {
                domain: 'popasuriromanesti.ro',
                title: 'hjFirstSeen',
                description: 'This cookie is used to determine if the visitor has visited the website before.',
                expiration: 'Session',
                type: 'HTTP',
            },
        ],
    },
    {
        type: CookieCategory.PREFERENCES,
        data: [
            {
                domain: 'popasuriromanesti.ro',
                title: 'darkmode',
                description: 'This cookie is used to store the user’s preference for dark mode.',
                expiration: '1 year',
                type: 'preferences',
            },
        ],
    },
    {
        type: CookieCategory.STATISTICS,
        data: [
            {
                domain: 'Google',
                title: 'gtag',
                description: 'This cookie is used for Google Tag Manager.',
                expiration: '1 year',
                type: 'statistics',
            },
        ],
    },
    {
        type: CookieCategory.UNCLASSIFIED,
        data: [
            {
                domain: 'popasuriromanesti.ro',
                title: 'clck',
                description: 'This is an unclassified cookie.',
                expiration: '1 year',
                type: 'unclassified',
            },
        ],
    },
];
