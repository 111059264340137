import { Col, Row, Tab } from 'react-bootstrap';
import React from 'react';
import { TabItem } from '../../../../types/tabItem';
import { useTranslation } from 'react-i18next';
import CookieConsent from './CookieConsent';
import CookieDetails from './CookieDetails';
import CookieAbout from './CookieAbout';
import HorizontalTabNav from '../../Tab/Horizontal/HorizontalTabNav';
import HorizontalTabContent from '../../Tab/Horizontal/HorizontalTabContent';

const CookieModal = () => {
    const { t } = useTranslation(['cookie']);

    const tabContents: TabItem[] = [
        {
            id: '1',
            key: 'cookie-tab-consent',
            icon: 'mdi mdi-checkbox-marked-circle-outline',
            title: t('tab_consent'),
            component: <CookieConsent />,
        },
        {
            id: '2',
            key: 'cookie-tab-details',
            icon: 'mdi mdi-file-document-outline',
            title: t('tab_details'),
            component: <CookieDetails />,
        },
        {
            id: '3',
            key: 'cookie-tab-about',
            icon: 'mdi mdi-information-outline',
            title: t('tab_about'),
            component: <CookieAbout />,
        },
    ];

    return (
        <>
            <Tab.Container defaultActiveKey='cookie-tab-details'>
                <Row className={'justify-content-center my-2'}>
                    <Col md={11} className='mb-2 mb-sm-0' style={{ letterSpacing: '0.05em', fontFamily: 'SansSerif, sans-serif' }}>
                        <HorizontalTabNav content={tabContents} justify hideIcons highlight variant={'pills'} />
                    </Col>
                </Row>
                <Row>
                    <Col className={'px-4 my-2'}>
                        <HorizontalTabContent content={tabContents} />
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
};

export default CookieModal;
