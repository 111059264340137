import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { StaticPageData } from '../../../types/cms/staticPage';

const api = new APICore();

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/blog_posts/admin/page/${page}/${size}`, null);
}

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/blog_posts/admin/${id}`, null);
}

function apiAdminCreate(data: StaticPageData) {
    return api.create(`${apiBaseUrl}/blog_posts/admin`, data);
}

function apiAdminUpdate(data: StaticPageData) {
    return api.update(`${apiBaseUrl}/blog_posts/admin`, data);
}

export { apiAdminViewAll, apiAdminCreate, apiAdminUpdate, apiAdminViewDetails };
