import { CountyActionTypes } from './constants';
import { CountyDetails } from '../../../types/constant/county';

export type CountyActionType = {
    type: CountyActionTypes.API_RESPONSE_ERROR | CountyActionTypes.API_RESPONSE_SUCCESS | CountyActionTypes.USER_VIEW_ALL | CountyActionTypes.RESET;
    payload: {} | string;
};

export const countyApiResponseSuccess = (actionType: string, data: CountyDetails | {}): CountyActionType => ({
    type: CountyActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const countyApiResponseError = (actionType: string, error: string): CountyActionType => ({
    type: CountyActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const countyUserViewAll = (): CountyActionType => ({
    type: CountyActionTypes.USER_VIEW_ALL,
    payload: {},
});

export const resetCounty = (): CountyActionType => ({
    type: CountyActionTypes.RESET,
    payload: {},
});
