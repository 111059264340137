import { PageActionTypes } from './constants';
import { StaticPageData } from '../../../types/cms/staticPage';

export type PageActionType = {
    type:
        | PageActionTypes.API_RESPONSE_SUCCESS
        | PageActionTypes.API_RESPONSE_ERROR
        | PageActionTypes.ADMIN_VIEW_ALL
        | PageActionTypes.ADMIN_VIEW_DETAILS
        | PageActionTypes.ADMIN_UPSERT
        | PageActionTypes.USER_VIEW;
    payload: {} | string;
};

export const staticPageApiResponseSuccess = (actionType: string, data: StaticPageData | {}): PageActionType => ({
    type: PageActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const staticPageApiResponseError = (actionType: string, error: string): PageActionType => ({
    type: PageActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const staticPageUserView = (slug: string): PageActionType => ({
    type: PageActionTypes.USER_VIEW,
    payload: { slug },
});

export const staticPageAdminAll = (page: number, size: number): PageActionType => ({
    type: PageActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const staticPageAdminView = (id: string): PageActionType => ({
    type: PageActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const staticPageAdminUpsert = (data: StaticPageData): PageActionType => ({
    type: PageActionTypes.ADMIN_UPSERT,
    payload: data,
});
