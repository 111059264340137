import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { contactApiResponseError, contactApiResponseSuccess } from './actions';
import { ContactActionTypes } from './constants';
import { apiAdminViewAll, apiAdminViewDetails, apiUserSubmit } from '../../../helpers/api/cms/contact';
import { ContactData as ContactDataProps, ContactSummary } from '../../../types/cms/contact';

type ContactPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type ContactData = {
    payload: ContactDataProps;
    type: string;
};

type AdminContactPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminContactPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        yield put(contactApiResponseSuccess(ContactActionTypes.ADMIN_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(contactApiResponseError(ContactActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id } }: ContactPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(contactApiResponseSuccess(ContactActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(contactApiResponseError(ContactActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* userSendContact({ payload }: ContactData): SagaIterator {
    try {
        const response = yield call(apiUserSubmit, payload);
        const contact = response.data;
        yield put(contactApiResponseSuccess(ContactActionTypes.USER_SEND_MESSAGE, contact));
    } catch (error: any) {
        yield put(contactApiResponseError(ContactActionTypes.USER_SEND_MESSAGE, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(ContactActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchUserSendContact() {
    yield takeEvery(ContactActionTypes.USER_SEND_MESSAGE, userSendContact);
}

export function* watchAdminViewDetails() {
    yield takeEvery(ContactActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

function* contactSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewDetails), fork(watchUserSendContact)]);
}

export default contactSaga;
