import { CountyActionTypes } from './constants';
import { CountyDetails } from '../../../types/constant/county';

const INIT_STATE = {
    apiSuccess: false,
    loading: false,
    userAllCounties: null,
};

type CountyActionType = {
    type: CountyActionTypes.API_RESPONSE_ERROR | CountyActionTypes.API_RESPONSE_SUCCESS | CountyActionTypes.USER_VIEW_ALL | CountyActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess?: boolean;
    userAllCounties?: CountyDetails[] | null;
    loading?: boolean;
};

const County = (state: State = INIT_STATE, action: CountyActionType) => {
    switch (action.type) {
        case CountyActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CountyActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        userAllCounties: action.payload.data,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CountyActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CountyActionTypes.USER_VIEW_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CountyActionTypes.USER_VIEW_ALL:
            return { ...state, loading: true };

        case CountyActionTypes.RESET:
            return {
                ...state,
                userAllCounties: null,
                loading: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default County;
