export enum PageActionTypes {
    API_RESPONSE_SUCCESS = '@@staticPage/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@staticPage/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@staticPage/ADMIN_VIEW_ALL',
    ADMIN_VIEW_DETAILS = '@@staticPage/ADMIN_VIEW_DETAILS',
    ADMIN_UPSERT = '@@staticPage/ADMIN_UPSERT',

    USER_VIEW = '@@staticPage/USER_VIEW',
}
