export enum ContactActionTypes {
    API_RESPONSE_SUCCESS = '@@contact/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@contact/API_RESPONSE_ERROR',

    ADMIN_VIEW_ALL = '@@contact/ADMIN_VIEW_ALL',
    ADMIN_VIEW_DETAILS = '@@contact/ADMIN_VIEW_DETAILS',

    USER_SEND_MESSAGE = '@@contact/USER_SEND_MESSAGE',

    RESET = '@@contact/RESET',
}
