import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { dashboardApiResponseSuccess, dashboardApiResponseError } from './actions';
import { PanelActionTypes } from './constants';
import { apiAdminSendGlobalMessage } from '../../helpers/api/panel';

type MessageData = {
    payload: {
        message: string;
    };
    type: string;
};

function* adminSendGlobalMessage({ payload: { message } }: MessageData): SagaIterator {
    try {
        const response = yield call(apiAdminSendGlobalMessage, message);
        yield put(dashboardApiResponseSuccess(PanelActionTypes.SEND_GLOBAL_MESSAGE, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(PanelActionTypes.SEND_GLOBAL_MESSAGE, error));
    }
}

export function* watchAdminSendGlobalMessage() {
    yield takeEvery(PanelActionTypes.SEND_GLOBAL_MESSAGE, adminSendGlobalMessage);
}

function* authSaga() {
    yield all([fork(watchAdminSendGlobalMessage)]);
}

export default authSaga;
