import { BlogPostActionTypes } from './constants';
import { BlogPostUpsert } from '../../../types/blog/post';

export type BlogPostActionType = {
    type:
        | BlogPostActionTypes.API_RESPONSE_SUCCESS
        | BlogPostActionTypes.API_RESPONSE_ERROR
        | BlogPostActionTypes.ADMIN_VIEW_ALL
        | BlogPostActionTypes.ADMIN_VIEW_DETAILS
        | BlogPostActionTypes.ADMIN_UPSERT;
    payload: {} | string;
};

export const blogPostApiResponseSuccess = (actionType: string, data: {}): BlogPostActionType => ({
    type: BlogPostActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const blogPostApiResponseError = (actionType: string, error: string): BlogPostActionType => ({
    type: BlogPostActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const blogPostAdminAll = (page: number, size: number): BlogPostActionType => ({
    type: BlogPostActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const blogPostAdminView = (id: string): BlogPostActionType => ({
    type: BlogPostActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const blogPostAdminUpsert = (data: BlogPostUpsert): BlogPostActionType => ({
    type: BlogPostActionTypes.ADMIN_UPSERT,
    payload: data,
});
