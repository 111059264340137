export type MenuItemType = {
    key: string;
    label?: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    adminKey?: boolean;
    target?: string;
    children?: MenuItemType[];
};

const ADMIN_URL_PREFIX = '/admin';
const ADMIN_MENU_ITEMS: MenuItemType[] = [
    {
        adminKey: true,
        key: 'admin-dashboards',
        label: 'Dashboard',
        icon: 'mdi mdi-home',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-main-page',
                url: `${ADMIN_URL_PREFIX}/dashboard/home`,
                parentKey: 'admin-dashboards',
            },
            {
                adminKey: true,
                key: 'admin-main-infrastructure',
                url: `${ADMIN_URL_PREFIX}/dashboard/infrastructure`,
                parentKey: 'admin-dashboards',
            },
            {
                adminKey: true,
                key: 'admin-main-quick',
                url: `${ADMIN_URL_PREFIX}/dashboard/quicks`,
                parentKey: 'admin-dashboards',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-shop',
        label: 'Shop',
        icon: 'mdi mdi-cart',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-shop-products',
                url: `${ADMIN_URL_PREFIX}/shop/products`,
                parentKey: 'admin-shop',
            },
            {
                adminKey: true,
                key: 'admin-shop-invoices',
                label: 'Testeaza problema',
                url: `${ADMIN_URL_PREFIX}/shop/invoices`,
                parentKey: 'admin-shop',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-blogs',
        label: 'Blog',
        icon: 'mdi mdi-rss',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-blogs-posts',
                url: `${ADMIN_URL_PREFIX}/blog/posts`,
                parentKey: 'admin-theory',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-users',
        label: 'Users',
        icon: 'mdi mdi-account',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-users-all',
                url: `${ADMIN_URL_PREFIX}/users/all`,
                parentKey: 'admin-theory',
            },
            {
                adminKey: true,
                key: 'admin-users-avatars',
                url: `${ADMIN_URL_PREFIX}/users/avatars`,
                parentKey: 'admin-theory',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-support',
        label: 'Suport',
        icon: 'mdi mdi-help',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-support-reports',
                url: `${ADMIN_URL_PREFIX}/support/reports`,
                parentKey: 'admin-support',
            },
            {
                adminKey: true,
                key: 'admin-support-tickets',
                url: `${ADMIN_URL_PREFIX}/support/tickets`,
                parentKey: 'admin-support',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-cms',
        label: 'CMS',
        icon: 'mdi mdi-cog',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-cms-pages',
                url: `${ADMIN_URL_PREFIX}/cms/pages`,
                parentKey: 'admin-cms',
            },
            {
                adminKey: true,
                key: 'admin-cms-contacts',
                url: `${ADMIN_URL_PREFIX}/cms/contacts`,
                parentKey: 'admin-cms',
            },
            {
                adminKey: true,
                key: 'admin-cms-gallery',
                url: `${ADMIN_URL_PREFIX}/cms/gallery`,
                parentKey: 'admin-cms',
            },
            {
                adminKey: true,
                key: 'admin-cms-seo',
                url: `${ADMIN_URL_PREFIX}/cms/seo`,
                parentKey: 'admin-cms',
            },
        ],
    },
    {
        adminKey: true,
        key: 'admin-logs',
        label: 'Logs',
        icon: 'mdi mdi-file-document-outline',
        parentKey: 'admin-panel',
        children: [
            {
                adminKey: true,
                key: 'admin-logs-security',
                url: `${ADMIN_URL_PREFIX}/logs/security`,
                parentKey: 'admin-logs',
            },
            {
                adminKey: true,
                key: 'admin-logs-logins',
                url: `${ADMIN_URL_PREFIX}/logs/logins`,
                parentKey: 'admin-logs',
            },
        ],
    },
];

const MENU_ITEMS: MenuItemType[] = [
    {
        key: 'dashboard',
        isTitle: false,
        icon: 'mdi mdi-home',
        url: '/dashboard',
    },
    {
        key: 'search',
        isTitle: false,
        icon: 'mdi mdi-magnify',
        url: '/search',
    },
    {
        key: 'hotels',
        isTitle: false,
        icon: 'mdi mdi-bed',
        url: '/hotels',
    },
    {
        key: 'objectives',
        isTitle: false,
        icon: 'mdi mdi-pin-outline',
        url: '/objectives',
    },
    {
        key: 'contact',
        isTitle: false,
        icon: 'mdi mdi-phone',
        url: '/contact',
    },
    {
        key: 'planner',
        isTitle: false,
        icon: 'mdi mdi-notebook-multiple',
        url: '/planner',
    },
];

export { ADMIN_MENU_ITEMS, MENU_ITEMS };
