import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { StaticPageData } from '../../../types/cms/staticPage';
import { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewDetails } from '../../../helpers/api/blog/post';
import { blogPostApiResponseError, blogPostApiResponseSuccess } from './actions';
import { BlogPostActionTypes } from './constants';

type AdminBlogPostsPaginatedPayloadType = {
    payload: {
        page: number;
        size: number;
    };
    type: string;
};

type AdminBlogPostsDetailsPayloadType = {
    payload: {
        id: string;
    };
    type: string;
};

type AdminBlogPostsUpsertPayloadType = {
    payload: StaticPageData;
    type: string;
};

function* adminViewAll({ payload: { page, size } }: AdminBlogPostsPaginatedPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewAll, page, size);
        yield put(blogPostApiResponseSuccess(BlogPostActionTypes.ADMIN_VIEW_ALL, response.data));
    } catch (error: any) {
        yield put(blogPostApiResponseError(BlogPostActionTypes.ADMIN_VIEW_ALL, error));
    }
}

function* adminViewDetails({ payload: { id } }: AdminBlogPostsDetailsPayloadType): SagaIterator {
    try {
        const response = yield call(apiAdminViewDetails, id);
        yield put(blogPostApiResponseSuccess(BlogPostActionTypes.ADMIN_VIEW_DETAILS, response.data));
    } catch (error: any) {
        yield put(blogPostApiResponseError(BlogPostActionTypes.ADMIN_VIEW_DETAILS, error));
    }
}

function* adminUpsert({ payload }: AdminBlogPostsUpsertPayloadType): SagaIterator {
    try {
        const response = yield call(payload.id ? apiAdminUpdate : apiAdminCreate, payload);
        yield put(blogPostApiResponseSuccess(BlogPostActionTypes.ADMIN_UPSERT, response.data));
    } catch (error: any) {
        yield put(blogPostApiResponseError(BlogPostActionTypes.ADMIN_UPSERT, error));
    }
}

export function* watchAdminViewAll() {
    yield takeEvery(BlogPostActionTypes.ADMIN_VIEW_ALL, adminViewAll);
}

export function* watchAdminViewDetails() {
    yield takeEvery(BlogPostActionTypes.ADMIN_VIEW_DETAILS, adminViewDetails);
}

export function* watchAdminUpsert() {
    yield takeEvery(BlogPostActionTypes.ADMIN_UPSERT, adminUpsert);
}

function* blogPostsSaga() {
    yield all([fork(watchAdminViewAll), fork(watchAdminViewDetails), fork(watchAdminUpsert)]);
}

export default blogPostsSaga;
