import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import BlogPost from './blog/post/reducers';
import Contact from './cms/contact/reducers';
import StaticPage from './cms/staticPage/reducers';
import County from './constant/counties/reducers';
import Layout from './layout/reducers';

export default combineReducers({
    Auth,
    BlogPost,
    Contact,
    County,
    Layout,
    StaticPage,
});
