import { BlogPostActionTypes } from './constants';
import { BlogPostDetails, BlogPostSummary, BlogPostUpsert } from '../../../types/blog/post';

const INIT_STATE = {
    apiSuccess: null,
    loading: false,
    adminAllBlogPosts: null,
    adminBlogPostDetails: null,
};

type BlogPostActionType = {
    type:
        | BlogPostActionTypes.API_RESPONSE_SUCCESS
        | BlogPostActionTypes.API_RESPONSE_ERROR
        | BlogPostActionTypes.ADMIN_VIEW_ALL
        | BlogPostActionTypes.ADMIN_UPSERT
        | BlogPostActionTypes.ADMIN_VIEW_DETAILS;
    payload: {
        actionType?: string;
        data?: BlogPostUpsert | BlogPostDetails | BlogPostSummary | {};
        error?: string;
    };
};

type State = {
    apiSuccess: null | boolean;
    adminAllBlogPosts: null | BlogPostSummary[];
    adminBlogPostDetails: null | BlogPostDetails;
    loading?: boolean;
};

const BlogPost = (state: State = INIT_STATE, action: BlogPostActionType) => {
    switch (action.type) {
        case BlogPostActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BlogPostActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllBlogPosts: action.payload.data,
                        loading: false,
                    };
                }
                case BlogPostActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminBlogPostDetails: action.payload.data,
                        loading: false,
                    };
                }
                case BlogPostActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case BlogPostActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return { ...state };
            }

        case BlogPostActionTypes.ADMIN_VIEW_ALL:
            return { ...state, loading: true, adminAllBlogPosts: null };

        case BlogPostActionTypes.ADMIN_VIEW_DETAILS:
            return { ...state, loading: true, adminBlogPostDetails: null };

        case BlogPostActionTypes.ADMIN_UPSERT:
            return { ...state, loading: true, apiSuccess: null };

        default:
            return { ...state };
    }
};

export default BlogPost;
