import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { CookieData } from '../../../../types/cookie';
import { groupBy } from '../../../../utils';
import { useTranslation } from 'react-i18next';

interface CookieDetailsContainerProps {
    title: string;
    description: string;
    cookies: CookieData[];
}

const CookieDetailsContainer = ({ title, description, cookies }: CookieDetailsContainerProps) => {
    const { t } = useTranslation(['cookie']);
    const cookiesGroupedByDomain = groupBy(cookies, 'domain');
    // @ts-ignore
    const domainKeys = [...cookiesGroupedByDomain.keys()];

    return (
        <Row style={{ letterSpacing: '0.05em', fontFamily: 'SansSerif, sans-serif' }}>
            <Col>
                <p style={{ fontWeight: 700, fontSize: '18px' }}>
                    {title} ({cookies.length})
                </p>
                <p>{description}</p>
                <div className={'border border-3 rounded-3 p-2 my-3'}>
                    {domainKeys.map((domain) => {
                        const cookiesByDomain = cookiesGroupedByDomain.get(domain);
                        return (
                            <div key={`domain_${domain}`}>
                                <span style={{ fontWeight: 700 }}>
                                    {domain} ({cookiesByDomain.length})
                                </span>
                                <hr />
                                {cookiesByDomain.map((cookie: CookieData) => {
                                    return (
                                        <div className={'border border-3 rounded-3 my-2 p-2'} key={`cookie_${cookie.title}`}>
                                            <div key={`cookie_${cookie.title}`}>
                                                <span style={{ fontWeight: 700 }}>{cookie.title}</span>
                                                <br />
                                                <br />
                                                <span>{cookie.description}</span>
                                                <hr />
                                                <span style={{ fontWeight: 700 }}>{t('type')}: </span>
                                                <span>{cookie.type}</span>
                                                <br />
                                                <span style={{ fontWeight: 700 }}>{t('expiration')}: </span>
                                                <span>{cookie.expiration}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </Col>
        </Row>
    );
};

export default CookieDetailsContainer;
