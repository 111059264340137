import { PanelActionTypes } from './constants';
import { UserData } from '../../types/user/user';

export type PanelActionType = {
    type: PanelActionTypes.SEND_GLOBAL_MESSAGE | PanelActionTypes.API_RESPONSE_SUCCESS | PanelActionTypes.API_RESPONSE_ERROR | PanelActionTypes.RESET;
    payload: {} | string;
};

export const dashboardSendGlobalMessage = (message: string): PanelActionType => ({
    type: PanelActionTypes.SEND_GLOBAL_MESSAGE,
    payload: { message },
});

export const dashboardApiResponseSuccess = (actionType: string, data: UserData | {}): PanelActionType => ({
    type: PanelActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const dashboardApiResponseError = (actionType: string, error: string): PanelActionType => ({
    type: PanelActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
