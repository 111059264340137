import moment from 'moment';
import 'moment/locale/ro';

export const convertToMoment = (apiDate: number[]): moment.Moment => {
    return moment(`${apiDate[0]}-${apiDate[1]}-${apiDate[2]} ${apiDate[3]}:${apiDate[4]}`).utcOffset(3, true);
};

export const convertToDateTime = (apiDate: number[]): string => {
    return convertToMoment(apiDate).utcOffset(3).format('YYYY-MM-DD HH:mm');
};

export const convertToDate = (apiDate: number[]): string => {
    return convertToMoment(apiDate).utcOffset(3).format('YYYY-MM-DD');
};

export const convertToHumanDate = (apiDate: number[], lang: string): string => {
    return convertToMoment(apiDate).locale(lang).fromNow();
};
