import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { CountyActionTypes } from './constants';
import { CountyDetails } from '../../../types/constant/county';
import { countyApiResponseError, countyApiResponseSuccess } from './actions';
import { apiUserViewAll } from '../../../helpers/api/constant/county';

function* userViewAll(): SagaIterator {
    try {
        const response = yield call(apiUserViewAll);
        const responseData: CountyDetails[] = response.data.sort((a: CountyDetails, b: CountyDetails) => a.name.localeCompare(b.name));
        const noCountyIdx = responseData.indexOf(responseData.find((county) => county.shortId === -1) as CountyDetails);
        responseData.splice(noCountyIdx, 1);
        yield put(countyApiResponseSuccess(CountyActionTypes.USER_VIEW_ALL, responseData));
    } catch (error: any) {
        yield put(countyApiResponseError(CountyActionTypes.USER_VIEW_ALL, error));
    }
}

export function* watchUserViewAll() {
    yield takeEvery(CountyActionTypes.USER_VIEW_ALL, userViewAll);
}

function* countySaga() {
    yield all([fork(watchUserViewAll)]);
}

export default countySaga;
