import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import blogPostSaga from './blog/post/saga';
import contactSaga from './cms/contact/saga';
import countySaga from './constant/counties/saga';
import dashboardSaga from './panel/saga';
import layoutSaga from './layout/saga';
import seoDataSaga from './cms/seoData/saga';
import staticPageSaga from './cms/staticPage/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        blogPostSaga(),
        contactSaga(),
        countySaga(),
        dashboardSaga(),
        layoutSaga(),
        seoDataSaga(),
        staticPageSaga(),
    ]);
}
