import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { CookieCategory, CookieCategoryDefinition } from '../../../types/cookie';
import { acceptCookies, createDefaultCookie, getCookieValue } from '../../../utils/cookie';
import useModal from '../../../hooks/useModal';
import CookieModal from './CookieModal/CookieModal';

const CookieBar = () => {
    const { t } = useTranslation(['cookie']);
    const [marketing, setMarketing] = React.useState<boolean>(true);
    const [preferences, setPreferences] = React.useState<boolean>(true);
    const [statistics, setStatistics] = React.useState<boolean>(true);
    const [cookiesAccepted, setCookiesAccepted] = React.useState<boolean>(getCookieValue() !== null);
    const { isOpen, className, toggleModal, openModalWithClass } = useModal();

    useEffect(() => {
        createDefaultCookie();
    }, []);

    const categories: CookieCategoryDefinition[] = [
        {
            id: 1,
            description: t('categories.mandatory.description'),
            title: t('categories.mandatory.title'),
            enabled: false,
            state: [true, () => {}],
            type: CookieCategory.MANDATORY,
        },
        {
            id: 2,
            description: t('categories.marketing.description'),
            title: t('categories.marketing.title'),
            enabled: true,
            state: [marketing, setMarketing],
            type: CookieCategory.MARKETING,
        },
        {
            id: 3,
            description: t('categories.preferences.description'),
            title: t('categories.preferences.title'),
            enabled: true,
            state: [preferences, setPreferences],
            type: CookieCategory.PREFERENCES,
        },
        {
            id: 4,
            description: t('categories.statistics.description'),
            title: t('categories.statistics.title'),
            enabled: true,
            state: [statistics, setStatistics],
            type: CookieCategory.STATISTICS,
        },
    ];

    const setPreference = (category: CookieCategoryDefinition) => {
        category.state[1](!category.state[0]);
    };

    const clickAcceptCookies = () => {
        acceptCookies();
        setCookiesAccepted(true);
    };

    return (
        <div className={'cookie_container'} style={{ display: cookiesAccepted ? 'none' : 'flex' }}>
            <div className={'cookie_bar'}>
                <Row>
                    <Col md={9}>
                        <Row className={'mb-1'}>
                            <Col>
                                <span className={'font-18 text-black'} style={{ letterSpacing: '0.05em', fontWeight: '700' }}>
                                    {t('title')}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span className={'font-14 text-black'} style={{ letterSpacing: '0.05em', lineHeight: '1.75rem' }}>
                                    {t('descriptionStart')}
                                </span>{' '}
                                <Link
                                    to={'/pages/politica-confidentialitate'}
                                    className={'font-14 text-primary'}
                                    style={{ letterSpacing: '0.05em', lineHeight: '1.75rem', fontWeight: '700' }}
                                    target={'_blank'}>
                                    {t('descriptionPrivacyTitle')}
                                </Link>
                                <span className={'font-14 text-black'} style={{ letterSpacing: '0.05em', lineHeight: '1.75rem' }}>
                                    {t('descriptionEnd')}
                                </span>{' '}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Row className={'mb-2'}>
                            <Col>
                                <Button variant={'primary'} className={'py-2 w-100 text-uppercase'} style={{ letterSpacing: '0.05em' }} onClick={clickAcceptCookies}>
                                    {t('buttonAllow')}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant={'outline-primary'} className={'py-2 w-100 text-uppercase'} style={{ letterSpacing: '0.05em' }} onClick={clickAcceptCookies}>
                                    {t('buttonDefault')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr className={'text-black'} />
                    </Col>
                </Row>
                <Row>
                    {categories.map((category) => {
                        return (
                            <Col md={2} xs={6} key={`cookie_category_${category.id}`}>
                                <Form.Check className='form-check mb-1'>
                                    <Form.Check
                                        type='switch'
                                        id={`category_${category.id}`}
                                        name={category.type}
                                        disabled={!category.enabled}
                                        checked={category.state[0]}
                                        onChange={(e) => setPreference(category)}
                                    />
                                    <Form.Check.Label htmlFor='condensed-check' className={'text-black font-16 ms-4'} style={{ letterSpacing: '0.05em' }}>
                                        {category.title}
                                    </Form.Check.Label>
                                </Form.Check>
                            </Col>
                        );
                    })}
                    <Col>
                        <span
                            className={'font-14 text-primary text-uppercase float-end'}
                            style={{ letterSpacing: '0.05em', fontWeight: '700', cursor: 'pointer' }}
                            onClick={() => {
                                openModalWithClass('modal-full-width');
                            }}>
                            {t('moreSettings')}
                        </span>
                    </Col>
                </Row>
                <Modal show={isOpen} onHide={toggleModal} dialogClassName={className} style={{ zIndex: 9999999 }}>
                    <Modal.Header onHide={toggleModal} closeButton className={'bg-primary text-white text-uppercase my-0'}>
                        <h4 className='fw-bolder m-0'>{t('settings')}</h4>
                    </Modal.Header>
                    <Modal.Body className={'p-0'} style={{ height: 600, overflowY: 'auto', overflowX: 'hidden' }}>
                        <CookieModal />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
};

export default CookieBar;
