import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';
import { ChapterNew } from '../../../types/theory/chapter';
import { SeoDataNew } from '../../../types/cms/seoData';

const api = new APICore();

function apiAdminViewDetails(id: string) {
    return api.get(`${apiBaseUrl}/seo/admin/${id}`, null);
}

function apiAdminViewAll(page: number, size: number) {
    return api.get(`${apiBaseUrl}/seo/admin/page/${page}/${size}`, null);
}

function apiAdminCreate(data: SeoDataNew) {
    return api.create(`${apiBaseUrl}/seo/admin`, data);
}

function apiAdminUpdate(data: ChapterNew) {
    return api.update(`${apiBaseUrl}/seo/admin`, data);
}

function apiUserViewAll() {
    return api.get(`${apiBaseUrl}/seo/all`, null);
}

function apiUserViewDetails(slug: string) {
    return api.get(`${apiBaseUrl}/seo/${slug}`, null);
}

export { apiAdminCreate, apiAdminUpdate, apiAdminViewAll, apiAdminViewDetails, apiUserViewAll, apiUserViewDetails };
