import { APICore } from '../apiCore';
import { apiBaseUrl } from '../../../constants';

const api = new APICore();

function apiUserViewAll() {
    return api.get(`${apiBaseUrl}/counties/all`, null);
}

export { apiUserViewAll };
