import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from '../constants';
import { useRedux } from 'hooks';
import { UserRoleType } from '../types/user/role';

// Static Pages
const StaticPage = React.lazy(() => import('pages/staticPages/Details'));

// Main Dashboard
const MainDashboard = React.lazy(() => import('pages/dashboard/Dashboard'));
const ContactUs = React.lazy(() => import('pages/dashboard/Contact'));

// Authentication pages
const Login = React.lazy(() => import('pages/auth/Login'));
const Logout = React.lazy(() => import('pages/auth/Logout'));
const Register = React.lazy(() => import('pages/auth/Register'));
const Confirm = React.lazy(() => import('pages/auth/Confirm'));
const Confirmed = React.lazy(() => import('pages/auth/Confirmed'));
const ForgetPassword = React.lazy(() => import('pages/auth/ForgetPassword'));
const LockScreen = React.lazy(() => import('pages/auth/LockScreen'));

// Landing pages
const Maintenance = React.lazy(() => import('pages/landing/Maintenance'));
const Landing = React.lazy(() => import('pages/landing/Landing'));

// Error pages
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const Page404 = React.lazy(() => import('pages/error/Page404'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// Admin Pages - Dashboard
const AdminMainPage = React.lazy(() => import('pages/admin/main/AdminMainPage'));

// Admin Pages - CMS
const AdminCmsContacts = React.lazy(() => import('pages/admin/cms/AdminCmsContacts'));
const AdminCmsContactModal = React.lazy(() => import('components/organisms/Admin/CMS/AdminCmsContactModal'));
const AdminCmsPages = React.lazy(() => import('pages/admin/cms/AdminCmsPages'));
const AdminCmsPageDetails = React.lazy(() => import('pages/admin/cms/AdminCmsPageDetails'));

// Admin Pages - Blog
const AdminBlogPosts = React.lazy(() => import('pages/admin/blog/AdminBlogPosts'));
const AdminBlogPostDetails = React.lazy(() => import('pages/admin/blog/AdminBlogPostDetails'));

const AdminCmsSeoData = React.lazy(() => import('pages/admin/cms/AdminSeoData'));
const AdminCmsSeoDataDetails = React.lazy(() => import('pages/admin/cms/AdminSeoDataDetails'));

const loading = () => <div className=''></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = HorizontalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        {
            path: '/',
            element: <Root />,
        },
        {
            path: '/home',
            element: <LoadComponent component={Maintenance} />,
        },
        {
            path: '*',
            element: <Layout />,
            children: [
                {
                    path: 'contact',
                    element: <LoadComponent component={ContactUs} />,
                },
                {
                    path: '*',
                    element: <LoadComponent component={Page404} />,
                },
            ],
        },
        {
            path: '/admin',
            element: <PrivateRoute roles={[UserRoleType.ADMIN]} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'home',
                            element: <LoadComponent component={AdminMainPage} />,
                        },
                    ],
                },
                {
                    path: 'cms',
                    children: [
                        {
                            path: 'pages',
                            element: <LoadComponent component={AdminCmsPages} />,
                        },
                        {
                            path: 'pages/:pageId',
                            element: <LoadComponent component={AdminCmsPageDetails} />,
                        },
                        {
                            path: 'contacts',
                            element: <LoadComponent component={AdminCmsContacts} />,
                        },
                        {
                            path: 'contacts/:formId',
                            element: <LoadComponent component={AdminCmsContactModal} />,
                        },
                        {
                            path: 'seo',
                            element: <LoadComponent component={AdminCmsSeoData} />,
                        },
                        {
                            path: 'seo/:seoId',
                            element: <LoadComponent component={AdminCmsSeoDataDetails} />,
                        },
                    ],
                },
                {
                    path: 'blog',
                    children: [
                        {
                            path: 'posts',
                            element: <LoadComponent component={AdminBlogPosts} />,
                        },
                        {
                            path: 'posts/:postId',
                            element: <LoadComponent component={AdminBlogPostDetails} />,
                        },
                    ]
                }
            ],
        },
        {
            path: '/',
            element: <PrivateRoute roles={[UserRoleType.ADMIN, UserRoleType.USER]} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    element: <LoadComponent component={MainDashboard} />,
                },
            ],
        },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'pages/:pageSlug',
                    element: <LoadComponent component={StaticPage} />,
                },
                {
                    path: 'account',
                    children: [
                        {
                            path: 'login',
                            element: <LoadComponent component={Login} />,
                        },
                        {
                            path: 'register',
                            element: <LoadComponent component={Register} />,
                        },
                        {
                            path: 'confirm',
                            element: <LoadComponent component={Confirm} />,
                        },
                        {
                            path: 'confirmed',
                            element: <LoadComponent component={Confirmed} />,
                        },
                        {
                            path: 'forget-password',
                            element: <LoadComponent component={ForgetPassword} />,
                        },
                        {
                            path: 'lock-screen',
                            element: <LoadComponent component={LockScreen} />,
                        },
                        {
                            path: 'logout',
                            element: <LoadComponent component={Logout} />,
                        },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
    ]);
};

export { AllRoutes };
