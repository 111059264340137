import { ContactActionTypes } from './constants';
import { ContactData, ContactSummary } from '../../../types/cms/contact';

export type ContactActionType = {
    type:
        | ContactActionTypes.API_RESPONSE_SUCCESS
        | ContactActionTypes.API_RESPONSE_ERROR
        | ContactActionTypes.ADMIN_VIEW_ALL
        | ContactActionTypes.ADMIN_VIEW_DETAILS
        | ContactActionTypes.USER_SEND_MESSAGE
        | ContactActionTypes.RESET;
    payload: {} | string;
};

export const contactUserSend = (data: ContactData): ContactActionType => ({
    type: ContactActionTypes.USER_SEND_MESSAGE,
    payload: data,
});

export const contactAdminViewAll = (page: number, size: number): ContactActionType => ({
    type: ContactActionTypes.ADMIN_VIEW_ALL,
    payload: { page, size },
});

export const contactAdminViewDetails = (id: string): ContactActionType => ({
    type: ContactActionTypes.ADMIN_VIEW_DETAILS,
    payload: { id },
});

export const contactApiResponseSuccess = (actionType: string, data: ContactSummary | {}): ContactActionType => ({
    type: ContactActionTypes.API_RESPONSE_SUCCESS,
    payload: {
        actionType,
        data,
    },
});

export const contactApiResponseError = (actionType: string, error: string): ContactActionType => ({
    type: ContactActionTypes.API_RESPONSE_ERROR,
    payload: {
        actionType,
        error,
    },
});

export const contactReset = (): ContactActionType => ({
    type: ContactActionTypes.RESET,
    payload: {},
});
