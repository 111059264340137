import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CookieAbout = () => {
    const { t } = useTranslation(['cookie']);
    return (
        <Row>
            <Col style={{ fontWeight: 300, letterSpacing: '0.05em', fontFamily: 'SansSerif, sans-serif' }}>
                <p>{t('about.description1')}</p>
                <p>{t('about.description2')}</p>
                <p>{t('about.description3')}</p>
                <span>{t('about.description4')}</span>{' '}
                <Link to='/pages/politica-confidentialitate' target='_blank' style={{ fontWeight: 600 }}>
                    {t('about.privacyPolicyTitle')}
                </Link>
                <br />
                <br />
                <span>{t('about.description5')}</span>{' '}
                <Link to='/pages/politica-privind-cookies' target='_blank' style={{ fontWeight: 600 }}>
                    {t('about.cookiePolicyTitle')}
                </Link>
                <br />
                <br />
                <p>{t('about.description6')}</p>
            </Col>
        </Row>
    );
};

export default CookieAbout;
