import { PageActionTypes } from './constants';
import { StaticPageData, StaticPageSummary } from '../../../types/cms/staticPage';

const INIT_STATE = {
    apiSuccess: null,
    loading: false,
    adminAllPages: null,
    adminPageDetails: null,
    userPageDetails: null,
};

type PageActionType = {
    type:
        | PageActionTypes.API_RESPONSE_SUCCESS
        | PageActionTypes.API_RESPONSE_ERROR
        | PageActionTypes.ADMIN_VIEW_ALL
        | PageActionTypes.ADMIN_UPSERT
        | PageActionTypes.ADMIN_VIEW_DETAILS
        | PageActionTypes.USER_VIEW;
    payload: {
        actionType?: string;
        data?: StaticPageData | {};
        error?: string;
    };
};

type State = {
    apiSuccess: null | boolean;
    adminAllPages: null | StaticPageSummary[];
    adminPageDetails: null | StaticPageData;
    userPageDetails: null | StaticPageData;
    loading?: boolean;
};

const StaticPage = (state: State = INIT_STATE, action: PageActionType) => {
    switch (action.type) {
        case PageActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PageActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        adminAllPages: action.payload.data,
                        loading: false,
                    };
                }
                case PageActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        adminPageDetails: action.payload.data,
                        loading: false,
                    };
                }
                case PageActionTypes.ADMIN_UPSERT: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case PageActionTypes.USER_VIEW: {
                    return {
                        ...state,
                        userPageDetails: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PageActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PageActionTypes.USER_VIEW: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case PageActionTypes.USER_VIEW:
            return { ...state, loading: true, userPageDetails: null };

        case PageActionTypes.ADMIN_VIEW_ALL:
            return { ...state, loading: true, adminAllPages: null };

        case PageActionTypes.ADMIN_VIEW_DETAILS:
            return { ...state, loading: true, adminPageDetails: null };

        case PageActionTypes.ADMIN_UPSERT:
            return { ...state, loading: true, apiSuccess: null };

        default:
            return { ...state };
    }
};

export default StaticPage;
