export enum UserRoleType {
    GUEST = 'GUEST',
    USER = 'USER',
    SUBSCRIBER = 'SUBSCRIBER',
    BUYER = 'BUYER',
    PROFESSOR = 'PROFESSOR',
    ADMIN = 'ADMIN',
}

export interface UserRole {
    type: UserRoleType;
    value: number;
}

export const userRoles: UserRole[] = [
    { type: UserRoleType.GUEST, value: -1 },
    { type: UserRoleType.USER, value: 0 },
    { type: UserRoleType.SUBSCRIBER, value: 30 },
    { type: UserRoleType.BUYER, value: 31 },
    { type: UserRoleType.PROFESSOR, value: 90 },
    { type: UserRoleType.ADMIN, value: 1339 },
];
