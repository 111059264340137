import { Col, Row } from 'react-bootstrap';
import React from 'react';
import CookieDetailsContainer from './CookieDetailsContainer';
import { useTranslation } from 'react-i18next';
import { siteCookies } from '../../../../types/cookie';

const CookieDetails = () => {
    const { t } = useTranslation(['cookie']);

    return (
        <>
            <Row>
                <Col>
                    <CookieDetailsContainer title={t('categories.mandatory.title')} description={t('categories.mandatory.description')} cookies={siteCookies[0].data} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CookieDetailsContainer title={t('categories.marketing.title')} description={t('categories.marketing.description')} cookies={siteCookies[1].data} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CookieDetailsContainer title={t('categories.preferences.title')} description={t('categories.preferences.description')} cookies={siteCookies[2].data} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CookieDetailsContainer title={t('categories.statistics.title')} description={t('categories.statistics.description')} cookies={siteCookies[3].data} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <CookieDetailsContainer title={t('categories.unclassified.title')} description={t('categories.unclassified.description')} cookies={siteCookies[4].data} />
                </Col>
            </Row>
        </>
    );
};

export default CookieDetails;
