import { ContactActionTypes } from './constants';
import { ContactDetails, ContactSummary } from '../../../types/cms/contact';

const INIT_STATE = {
    loading: false,
    apiSuccess: false,
    adminAllContacts: null,
    adminContactDetails: null,
};

type ContactActionType = {
    type:
        | ContactActionTypes.API_RESPONSE_SUCCESS
        | ContactActionTypes.API_RESPONSE_ERROR
        | ContactActionTypes.ADMIN_VIEW_ALL
        | ContactActionTypes.ADMIN_VIEW_DETAILS
        | ContactActionTypes.USER_SEND_MESSAGE
        | ContactActionTypes.RESET;
    payload: {
        actionType?: string;
        data?: {};
        error?: string;
    };
};

type State = {
    apiSuccess: boolean;
    adminAllContacts?: ContactSummary[] | null;
    adminContactDetails?: ContactDetails | null;
    loading?: boolean;
};

const Contact = (state: State = INIT_STATE, action: ContactActionType) => {
    switch (action.type) {
        case ContactActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ContactActionTypes.USER_SEND_MESSAGE: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                    };
                }
                case ContactActionTypes.ADMIN_VIEW_ALL: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                        adminAllContacts: action.payload.data,
                    };
                }
                case ContactActionTypes.ADMIN_VIEW_DETAILS: {
                    return {
                        ...state,
                        apiSuccess: true,
                        loading: false,
                        adminContactDetails: action.payload.data,
                    };
                }
                default:
                    return { ...state };
            }

        case ContactActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ContactActionTypes.USER_SEND_MESSAGE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        apiSuccess: false,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ContactActionTypes.RESET:
            return {
                ...state,
                apiSuccess: false,
                loading: false,
                error: false,
            };
        default:
            return { ...state };
    }
};

export default Contact;
